<template>
  <v-card class="mt-2">
    <v-card-title
      >{{ $helpers.string.capitalize($tc("user.user", 2)) }}
      <v-btn v-if="canAddUser" class="ml-4" @click="addUser()" fab x-small color="primary"
        ><v-icon>mdi-plus</v-icon></v-btn
      ></v-card-title
    >
    <v-card-text>
      <v-container fluid>
        <!-- <v-btn color="primary" @click="newClassroom">
      <v-icon>mdi-plus</v-icon>
      <span>{{ $t('classrooms.add') }}</span>
    </v-btn>-->
        <v-data-table
          :headers="headers"
          :items="users"
          hide-default-footer
          disable-pagination
          hide-default-header
          :search="search"
          :loading="loading"
        >
          <template v-slot:item.index="{ item }">
            <v-chip>{{ users.indexOf(item) + 1 }}</v-chip>
          </template>
          <template v-slot:item.clientName="{ item }">{{
            getClientName(item)
          }}</template>
          <template v-slot:item.createdAt="{ item }">{{
            $helpers.date.getFullDateTimeDisplay(item.createdAt)
          }}</template>
          <template v-slot:item.action="{ item }">
            <!-- <v-btn @click="payUser(item)" :loading="loadingPayUser" color="success"
          >pay<v-icon dark right> mdi-arrow-right </v-icon></v-btn
        > -->
            <v-btn @click="editUser(item)" icon><v-icon>mdi-eye</v-icon></v-btn>
            <v-btn
              @click="inactivateUser(item)"
              icon
              v-if="currentUserId !== item.id && !item.main"
              ><v-icon>mdi-minus-circle-outline</v-icon></v-btn
            >
          </template>
        </v-data-table>
        <UserEditionPopup
          ref="userEdition"
          @onSaveClicked="saveClicked"
          @onCloseClicked="retrieveAll"
        />
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import UserEditionPopup from "@/components/Admin/User/UserEditionPopup";

export default {
  components: {
    UserEditionPopup,
  },
  data() {
    return {
      users: [],
      user: null,
      search: "",
      headers: [
        { text: "index", value: "index", width: "60px" },
        { text: "Client", value: "clientName" },
        { text: "E-mail", value: "email" },
        { text: "createdAt", value: "createdAt" },
        {
          text: "actions",
          value: "action",
          sortable: false,
          width: 150,
        },
      ],
      loading: true,
      loadingPayUser: false,
    };
  },
  computed: {
    currentUserId() {
      return this.user?.id;
    },
    canAddUser() {
      return true;
    },
  },
  methods: {
    getClientName(client) {
      return `${client.firstName ?? ""} ${client.lastName ?? ""} ${
        client.companyName ?? ""
      }`.trim();
    },
    async addUser() {
      const { data } = await this.$services.user.simulateAdd();

      console.dir(data);

      var confirm = await this.$root.$confirm(
        this.$helpers.string.capitalizeI18N("confirmation"),
        this.$t("user.add.message", {
          nbDays: Math.abs(data.differenceNbDays),
          newValidityDateEnd: this.$helpers.date.getDateDisplay(
            data.newValidityDateEnd
          ),
          oldValidityDateEnd: this.$helpers.date.getDateDisplay(
            data.oldValidityDateEnd
          ),
        })
      );

      if (!confirm) return;

      this.$refs.userEdition.open({});
    },
    async retrieveAll() {
      this.loading = true;

      this.user = await this.$store.dispatch("adminauth/fetchUser");

      const users = await this.$services.user.getAll();

      this.users = users.data;

      this.loading = false;
    },
    async payUser(user) {
      try {
        this.loadingPayUser = true;

        const { data } = await this.$services.user.pay(
          user.id,
          this.$i18n.locale
        );

        window.location.href = data.paymentUrl;
      } catch (e) {
        this.$helpers.snackbar.showErrorI18N(e);
      } finally {
        this.loadingPayUser = false;
      }
    },
    saveClicked() {
      this.$router.go();
    },
    editUser(user) {
      var value = JSON.parse(JSON.stringify(user));
      this.$refs.userEdition.open(value);
    },
    async inactivateUser(user) {
      const { data } = await this.$services.user.simulateInactivate(user.id);

      console.dir(data);

      var confirm = await this.$root.$confirm(
        this.$helpers.string.capitalizeI18N("confirmation"),
        this.$t("user.inactivate.message", {
          nbDays: Math.abs(data.differenceNbDays),
          newValidityDateEnd: this.$helpers.date.getDateDisplay(
            data.newValidityDateEnd
          ),
          oldValidityDateEnd: this.$helpers.date.getDateDisplay(
            data.oldValidityDateEnd
          ),
        })
      );

      if (!confirm) return;

      await this.$services.user.inactivate(user.id);

      this.$router.go();
    },
  },

  async mounted() {
    await this.retrieveAll();
  },
};
</script>