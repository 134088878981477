import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{ref:"dialog",attrs:{"return-value":_vm.user,"persistent":"","max-width":"800px"},on:{"update:returnValue":function($event){_vm.user=$event},"update:return-value":function($event){_vm.user=$event},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.user.id == null ? _vm.$helpers.string.capitalizeI18N("user.creation") : _vm.user.firstName + " " + _vm.user.lastName))]),_c(VSpacer),_c('PopupCloseButton',{on:{"closeClick":_vm.close}})],1),_c(VCardText,[_c(VForm,{ref:"form"},[_c('FormGroupElement',{attrs:{"showMoreButton":false,"expanded":true}},[_c(VContainer,[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":_vm.$helpers.string.capitalizeI18N('first-name', {
                        required: true,
                      }),"prepend-icon":"mdi-account","rules":_vm.$helpers.rule.notEmpty(),"required":"","autofocus":""},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})],1),_c(VCol,[_c(VTextField,{attrs:{"label":_vm.$helpers.string.capitalizeI18N('last-name', {
                        required: true,
                      }),"prepend-icon":"mdi-account","rules":_vm.$helpers.rule.notEmpty(),"required":""},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})],1)],1),_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":_vm.$helpers.string.capitalizeI18N('email', {
                        required: true,
                      }),"type":"email","rules":_vm.$helpers.rule.email(),"prepend-icon":"mdi-email","required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1),_c(VRow,[_c(VCol,[(!_vm.user.id)?_c(VCheckbox,{attrs:{"label":"Envoyer un email de bienvenue avec le mot de passe"},model:{value:(_vm.user.send_email_welcome_user),callback:function ($$v) {_vm.$set(_vm.user, "send_email_welcome_user", $$v)},expression:"user.send_email_welcome_user"}}):_vm._e()],1)],1)],1)],1)],1),_c('small',[_vm._v("* "+_vm._s(_vm.$t("required-fields")))])],1),_c(VCardActions,[_c(VSpacer),_c('CardActionButton',{ref:"saveButton",attrs:{"type":"save"},on:{"click":_vm.saveAndClose}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }