<template>
  <v-container>
    <v-card>
      <v-card-title>
        <v-row align="center">
          <v-avatar size="96" class="mr-4">
            <v-img
              lazy-src="@/assets/blank-profile-picture.png"
              :src="imageUrl"
              alt="?"
              contain
            />
          </v-avatar>

          <v-badge color="transparent">
            <template v-slot:default>
              <v-file-input
                @change="uploadImage"
                hide-input
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-pencil"
              >
              </v-file-input>
            </template>
          </v-badge>

          <span class="ml-4"
            >{{ organizationName
            }}<v-btn plain @click="editOrganization()"
              ><v-icon small left>mdi-pencil</v-icon>modifier les
              paramètres</v-btn
            ></span
          >
          <v-spacer />
          <v-btn
            hidden
            style="text-transform: none !important"
            class="mr-2"
            text
            v-if="organizationUrl"
            :to="'/' + this.organizationUrl"
            >www.monplanning.be/{{ organizationUrl }}
            <v-icon class="ml-4" small>mdi-open-in-new</v-icon></v-btn
          >
        </v-row>
      </v-card-title>
      <v-card-text>
        <OrganizationOverview />
      </v-card-text>
    </v-card>

    <!-- <v-card class="mt-2">
      <v-card-title>My plan</v-card-title>
      <v-card-text>
        <OrganizationBillPlan />
      </v-card-text>
    </v-card> -->

    <OrganizationUsers />

    <OrganizationPayments />

    <OrganizationTransactions />

    <OrganizationEditionPopup
      ref="organizationEdition"
      @onSaveClicked="saveClicked"
      @onCloseClicked="retrieveAll"
    />
  </v-container>
</template>

<script>
import OrganizationUsers from "@/components/Admin/Organization/OrganizationUsers";
import OrganizationPayments from "@/components/Admin/Organization/OrganizationPayments";
import OrganizationTransactions from "@/components/Admin/Organization/OrganizationTransactions";
import OrganizationOverview from "@/components/Admin/Organization/OrganizationOverview";
import OrganizationEditionPopup from "@/components/Admin/Organization/OrganizationEditionPopup";

export default {
  components: {
    OrganizationUsers,
    OrganizationPayments,
    OrganizationOverview,
    OrganizationEditionPopup,
    OrganizationTransactions,
  },
  data() {
    return {
      organization: null,
      imageUrl: null,
    };
  },
  computed: {
    organizationName() {
      return this.organization?.name;
    },
    organizationUrl() {
      return this.organization?.url;
    },
  },
  methods: {
    editOrganization() {
      var value = JSON.parse(JSON.stringify(this.organization));
      console.dir(value);
      this.$refs.organizationEdition.open(value);
    },
    saveClicked() {
      this.retrieveAll();
    },
    async uploadImage(e) {
      if (e == null) return;

      try {
        this.organization = await this.$store.dispatch(
          "organization/uploadImage",
          e
        );

        this.imageUrl = this.$store.getters["organization/getImageUrl"];
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      }
    },
    async retrieveAll() {
      try {
        this.organization = await this.$store.dispatch(
          "organization/fetchOrganization"
        );

        this.imageUrl = this.$store.getters["organization/getImageUrl"];
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      }
    },
  },
  async mounted() {
    this.retrieveAll();
  },
};
</script>