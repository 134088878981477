import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,[_c(VCardTitle,[_c(VRow,{attrs:{"align":"center"}},[_c(VAvatar,{staticClass:"mr-4",attrs:{"size":"96"}},[_c(VImg,{attrs:{"lazy-src":require("@/assets/blank-profile-picture.png"),"src":_vm.imageUrl,"alt":"?","contain":""}})],1),_c(VBadge,{attrs:{"color":"transparent"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c(VFileInput,{attrs:{"hide-input":"","accept":"image/png, image/jpeg, image/bmp","prepend-icon":"mdi-pencil"},on:{"change":_vm.uploadImage}})]},proxy:true}])}),_c('span',{staticClass:"ml-4"},[_vm._v(_vm._s(_vm.organizationName)),_c(VBtn,{attrs:{"plain":""},on:{"click":function($event){return _vm.editOrganization()}}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("mdi-pencil")]),_vm._v("modifier les paramètres")],1)],1),_c(VSpacer),(_vm.organizationUrl)?_c(VBtn,{staticClass:"mr-2",staticStyle:{"text-transform":"none !important"},attrs:{"hidden":"","text":"","to":'/' + this.organizationUrl}},[_vm._v("www.monplanning.be/"+_vm._s(_vm.organizationUrl)+" "),_c(VIcon,{staticClass:"ml-4",attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1):_vm._e()],1)],1),_c(VCardText,[_c('OrganizationOverview')],1)],1),_c('OrganizationUsers'),_c('OrganizationPayments'),_c('OrganizationTransactions'),_c('OrganizationEditionPopup',{ref:"organizationEdition",on:{"onSaveClicked":_vm.saveClicked,"onCloseClicked":_vm.retrieveAll}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }