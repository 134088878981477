<template>
  <v-row justify="center">
    <v-dialog ref="dialog" v-model="dialog" :return-value.sync="organization" persistent max-width="800px"
      @keydown.esc="close()" :fullscreen="$helpers.display.mobileDialog" :hide-overlay="$helpers.display.mobileDialog"
      transition="dialog-bottom-transition">
      <v-card>
        <v-card-title>
          <span class="headline">{{ organization.name }}</span>
          <v-spacer />
          <PopupCloseButton @closeClick="close" />
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <FormGroupElement :showMoreButton="false" :expanded="true">
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N('name', {
                      required: true,
                    })
                      " v-model="organization.name" required autofocus :rules="$helpers.rule.notEmpty()"
                      @change="nameChanged($event)" prepend-icon="mdi-card-bulleted"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N('phone-number')"
                      v-model="organization.phoneNumber" type="phone" prepend-icon="mdi-phone"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N('website')" v-model="organization.website"
                      type="url" prepend-icon="mdi-web-box" pattern="https://.*"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N('vat-number')" v-model="organization.vatNumber"
                      prepend-icon="mdi-card-text" clearable></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N('bank-account-number')
                      " v-model="organization.bankAccountNumber" prepend-icon="mdi-bank-check"
                      clearable></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </FormGroupElement>
            <FormGroupElement color="blue" :showMoreButton="false" :expanded="true">
              <template v-slot:title>
                <span class="title">{{ $helpers.string.capitalizeI18N("my-public-page") }}
                </span>
              </template>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N('url')" v-model="organization.url"
                      prefix="monplanning.be/" prepend-icon="mdi-web-box" clearable></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N(
                      'number-hour-cancellation'
                    )
                      " :hint="$helpers.string.capitalizeI18N(
                        'number-hour-cancellation-hint'
                      )
                        " persistent-hint v-model="organization.nbHourCancellation" type="number"
                      :placeholder="$helpers.string.capitalizeI18N('no-limit')" prepend-icon="mdi-table-cancel"
                      clearable></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field :label="$helpers.string.capitalizeI18N('number-hour-booking')
                      " :hint="$helpers.string.capitalizeI18N(
                        'number-hour-booking-hint'
                      )
                        " persistent-hint v-model="organization.nbHourBooking" type="number"
                      :placeholder="$helpers.string.capitalizeI18N('no-limit')" prepend-icon="mdi-table-account"
                      clearable></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div  class="text-h6 mb-2">
                      Termes et conditions
                    </div>
                    <HTMLEditor :modelValue="organization.termsAndConditions"
                      @update:model-value="(value) => organization.termsAndConditions = value" />
                  </v-col>
                </v-row>
              </v-container>
            </FormGroupElement>
            <FormGroupElement :showMoreButton="false" :expanded="true" color="info">
              <template v-slot:title>
                <span class="title">{{ $helpers.string.capitalizeI18N('payment') }}
                </span>
              </template>
              <v-container>
                <v-row>
                  <v-col>
                    <v-checkbox v-model="organization.payment_types" value="cash"
                      :label="$t('payment-types.cash.title')" :hint="$t('payment-types.cash.hint')" persistent-hint />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox v-model="organization.payment_types" value="sepa"
                      :label="$t('payment-types.sepa.title')" :hint="$t('payment-types.sepa.hint')" persistent-hint />
                  </v-col>
                </v-row>
                <v-row align="center" justify="start">
                  <v-col class="shrink">
                    <v-img src="/static/image/paymenttypes/mollie_logo.png" width="80" />
                  </v-col>
                  <v-col>
                    <BillPlanRequired :billPlan="billPlan" />
                    <v-checkbox v-model="organization.payment_types" value="mollie"
                      :label="$t('payment-types.mollie.title')" :hint="$t('payment-types.mollie.hint')" persistent-hint
                      :disabled="billPlan == 'starter'" />
                  </v-col>
                  <v-col>
                    <v-text-field v-show="organization.payment_types
                      ? organization.payment_types.includes('mollie')
                      : false
                      " :label="$helpers.string.capitalizeI18N('mollie-api-key')" v-model="organization.mollieApiKey"
                      :hint="$helpers.string.capitalizeI18N('mollie-api-key-hint')
                        " persistent-hint clearable></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </FormGroupElement>
            <FormGroupAddressEdition color="success" v-model="organization.address" />
          </v-form>
          <small>* {{ $t("required-fields") }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CardActionButton @click="saveAndClose" type="save" ref="saveButton" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import CardActionButton from "@/components/Admin/Card/CardActionButton";
import FormGroupElement from "@/components/Admin/FormGroupElement";
import FormGroupAddressEdition from "../Address/FormGroupAddressEdition";
import PopupCloseButton from "@/components/Admin/PopupCloseButton";
import BillPlanRequired from "@/components/Admin/BillPlanRequired";

import Address from "@/models/address";
import HTMLEditor from "../HTMLEditor.vue";

export default {
  name: "OrganizationEditionPopup",
  data() {
    return {
      dialog: false,
      organization: {},
      menu: false,
    };
  },
  components: {
    CardActionButton,
    FormGroupElement,
    FormGroupAddressEdition,
    PopupCloseButton,
    BillPlanRequired,
    HTMLEditor,
  },
  computed: {
    billPlan() {
      return this.organization?.billPlan;
    },
  },
  methods: {
    nameChanged(e) {
      if (this.organization.url === "" || this.organization.url === null) {
        this.organization.url = e.replace(/[^0-9a-z]/gi, "");
      }
    },
    async saveOrganization() {
      try {
        this.organization = await this.$store.dispatch(
          "organization/saveOrganization",
          this.organization
        );

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    loadOrganization() {
      if (!this.organization.address) this.organization.address = new Address();

      if (this.organization.payment_types)
        this.organization.payment_types = this.organization.payment_types.map(
          (pt) => pt.id
        );
    },
    async saveAndClose() {
      try {
        this.$refs.saveButton.loading = true;

        if (!this.$refs.form.validate()) return;

        await this.saveOrganization();

        this.$helpers.snackbar.showSuccessI18N("organization.save.success");

        this.$emit("onSaveClicked", this.organization);
        this.dialog = false;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      } finally {
        this.$refs.saveButton.loading = false;
      }
    },
    saveAndNew() {
      this.saveOrganization();
      this.$emit("onSaveClicked", this.organization);
      this.organization = {};
      this.loadOrganization();
      this.$refs.name.focus();
    },
    close() {
      this.dialog = false;
      this.$emit("onCloseClicked");
    },
    open(organization) {
      this.organization = organization;
      this.loadOrganization();
      this.dialog = true;
    },
  },
};
</script>