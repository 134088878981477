<template>
  <v-row justify="center">
    <v-dialog
      ref="dialog"
      v-model="dialog"
      :return-value.sync="user"
      persistent
      max-width="800px"
      @keydown.esc="close()"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{
            user.id == null
              ? $helpers.string.capitalizeI18N("user.creation")
              : user.firstName + " " + user.lastName
          }}</span>
          <v-spacer />
          <PopupCloseButton @closeClick="close" />
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <FormGroupElement :showMoreButton="false" :expanded="true">
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      :label="
                        $helpers.string.capitalizeI18N('first-name', {
                          required: true,
                        })
                      "
                      v-model="user.firstName"
                      prepend-icon="mdi-account"
                      :rules="$helpers.rule.notEmpty()"
                      required
                      autofocus
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      :label="
                        $helpers.string.capitalizeI18N('last-name', {
                          required: true,
                        })
                      "
                      v-model="user.lastName"
                      prepend-icon="mdi-account"
                      :rules="$helpers.rule.notEmpty()"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      :label="
                        $helpers.string.capitalizeI18N('email', {
                          required: true,
                        })
                      "
                      v-model="user.email"
                      type="email"
                      :rules="$helpers.rule.email()"
                      prepend-icon="mdi-email"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-checkbox
                      v-model="user.send_email_welcome_user"
                      label="Envoyer un email de bienvenue avec le mot de passe"
                      v-if="!user.id"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </FormGroupElement>
          </v-form>
          <small>* {{ $t("required-fields") }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CardActionButton
            @click="saveAndClose"
            type="save"
            ref="saveButton"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import CardActionButton from "@/components/Admin/Card/CardActionButton";
import FormGroupElement from "@/components/Admin/FormGroupElement";
import PopupCloseButton from "@/components/Admin/PopupCloseButton";

export default {
  name: "UserEditionPopup",
  data() {
    return {
      dialog: false,
      user: {},
      menu: false,
    };
  },
  components: {
    CardActionButton,
    FormGroupElement,
    PopupCloseButton,
  },
  methods: {
    async saveUser() {
      try {
        const response = await this.$services.user.save(this.user);

        this.user = response.data;

        const loggedUser = await this.$store
          .dispatch("adminauth/fetchUser")
          .catch(() => {});

        if (this.user.id == loggedUser.id) {
          await this.$store
            .dispatch("adminauth/resetAndFetchUser")
            .catch(() => {});
        }

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    loadUser() {},
    async saveAndClose() {
      try {
        this.$refs.saveButton.loading = true;

        if (!this.$refs.form.validate()) return;

        await this.saveUser();

        this.$helpers.snackbar.showSuccessI18N("user.save.success");

        this.$emit("onSaveClicked", this.user);
        this.dialog = false;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      } finally {
        this.$refs.saveButton.loading = false;
      }
    },
    saveAndNew() {
      this.saveUser();
      this.$emit("onSaveClicked", this.user);
      this.user = {};
      this.loadUser();
      this.$refs.name.focus();
    },
    close() {
      this.dialog = false;
      this.$emit("onCloseClicked");
    },
    open(user) {
      this.user = user;
      this.loadUser();
      this.dialog = true;
    },
  },
};
</script>