<template>
    <div>
        <!-- Use the component in the right place of the template -->
        <tiptap-vuetify v-model="internalContent" @input="valueChanged" :extensions="extensions" min-height="200"
        max-height="200" />
    </div>
</template>

<script>
// import the component and the necessary extensions
import {
    TiptapVuetify, Heading, Bold, Italic, Strike, Underline,
    // Code, 
    Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History
} from 'tiptap-vuetify'

export default {
    components: {
        TiptapVuetify
    },
    props: {
        modelValue: String,
    },
    emits: ['update:model-value'],
    data() {
        return {
            // declare extensions you want to use
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }],
                Bold,
                // Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            internalContent: this.modelValue ?? ''
        }
    },
    methods: {
        valueChanged() {
            this.$emit("update:model-value", this.internalContent);
        }
    },
}
</script>
<!-- 
<style lang="css" scoped>
.tiptap-vuetify-editor__content {
    max-height: 300px;
}
</style> -->