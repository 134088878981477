<template>
  <v-card class="mt-2" v-if="transactions.length > 0">
    <v-card-title>{{
      $helpers.string.capitalizeI18N("transactions")
    }}</v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-data-table
          :headers="headers"
          :items="transactions"
          hide-default-footer
          disable-pagination
          hide-default-header
          :search="search"
          :loading="loading"
        >
          <template v-slot:item.index="{ item }">
            <v-chip>{{
              transactions.length - transactions.indexOf(item)
            }}</v-chip>
          </template>
          <template v-slot:item.createdAt="{ item }">{{
            $helpers.date.getDateDisplay(item.createdAt)
          }}</template>
          <template v-slot:item.description="{ item }">
            <div v-html="getDescription(item)"></div>
          </template>
        </v-data-table>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      transactions: [],
      search: "",
      headers: [
        { text: "index", value: "index", width: "60px" },
        { text: "Date", value: "createdAt" },
        { text: "Description", value: "description" },
      ],
      loading: true,
    };
  },
  computed: {},
  methods: {
    getDescription(transaction) {
      return this.$helpers.organization.getTransactionActionDescription(transaction);
    },
    async retrieveAll() {
      this.loading = true;

      const transactions = await this.$services.organization.getTransactions();

      this.transactions = transactions.data;
      console.dir(this.transactions);
      this.loading = false;
    },
  },

  async mounted() {
    await this.retrieveAll();
  },
};
</script>