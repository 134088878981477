import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mt-2"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$helpers.string.capitalize(_vm.$tc("user.user", 2)))+" "),(_vm.canAddUser)?_c(VBtn,{staticClass:"ml-4",attrs:{"fab":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.addUser()}}},[_c(VIcon,[_vm._v("mdi-plus")])],1):_vm._e()],1),_c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.users,"hide-default-footer":"","disable-pagination":"","hide-default-header":"","search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_c(VChip,[_vm._v(_vm._s(_vm.users.indexOf(item) + 1))])]}},{key:"item.clientName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getClientName(item)))]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$helpers.date.getFullDateTimeDisplay(item.createdAt)))]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.editUser(item)}}},[_c(VIcon,[_vm._v("mdi-eye")])],1),(_vm.currentUserId !== item.id && !item.main)?_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){return _vm.inactivateUser(item)}}},[_c(VIcon,[_vm._v("mdi-minus-circle-outline")])],1):_vm._e()]}}])}),_c('UserEditionPopup',{ref:"userEdition",on:{"onSaveClicked":_vm.saveClicked,"onCloseClicked":_vm.retrieveAll}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }