import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.transactions.length > 0)?_c(VCard,{staticClass:"mt-2"},[_c(VCardTitle,[_vm._v(_vm._s(_vm.$helpers.string.capitalizeI18N("transactions")))]),_c(VCardText,[_c(VContainer,{attrs:{"fluid":""}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.transactions,"hide-default-footer":"","disable-pagination":"","hide-default-header":"","search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_c(VChip,[_vm._v(_vm._s(_vm.transactions.length - _vm.transactions.indexOf(item)))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.$helpers.date.getDateDisplay(item.createdAt)))]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.getDescription(item))}})]}}],null,false,1767225386)})],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }