<template>
  <v-card class="mt-2" v-if="payments.length > 0">
    <v-card-title>{{
      $helpers.string.capitalizeI18N("invoices")
    }}</v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-data-table
          :headers="headers"
          :items="payments"
          hide-default-footer
          disable-pagination
          hide-default-header
          :search="search"
          :loading="loading"
        >
          <template v-slot:item.index="{ item }">
            <v-chip>{{ payments.length - payments.indexOf(item) }}</v-chip>
          </template>
          <template v-slot:item.date="{ item }">{{
            $helpers.date.getDateDisplay(item.date)
          }}</template>
          <template v-slot:item.amount="{ item }">
            <v-chip color="success" small label>{{
              item.amount
            }}</v-chip></template
          >
          <template v-slot:item.description="{ item }">
            <div v-html="getDescription(item)"></div>
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn
              @click="getPDFPaymentInvoice(item)"
              text
              color="primary"
              outlined
            >
              <v-icon left>mdi-file-pdf-box</v-icon>Facture
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      payments: [],
      search: "",
      headers: [
        { text: "index", value: "index", width: "60px" },
        { text: "Date", value: "date" },
        { text: "Amount", value: "amount" },
        { text: "Description", value: "description" },
        {
          text: "actions",
          value: "action",
          sortable: false,
          width: 150,
        },
      ],
      loading: true,
    };
  },
  computed: {},
  methods: {
    getDescription(payment) {
      return payment.organization_transactions
        .map((organizationTransaction) => {
          return this.$helpers.organization.getTransactionActionDescription(organizationTransaction);
        })
        .join("<br />");
    },
    async retrieveAll() {
      this.loading = true;

      const payments = await this.$services.organization.getPayments();

      this.payments = payments.data;
      console.dir(this.payments);
      this.loading = false;
    },
    async getPDFPaymentInvoice(organizationPayment) {
      const doc = await this.$services.organization.getPaymentInvoice(
        organizationPayment.id
      );

      const linkSource = `data:application/pdf;base64,${doc.data}`;
      const downloadLink = document.createElement("a");
      const fileName = `invoice ${organizationPayment.reference}`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
  },

  async mounted() {
    await this.retrieveAll();
  },
};
</script>